import React from 'react';
import { Toaster } from 'react-hot-toast';

import Mainroute from './routes/Mainroute';

function App() {

  return (
    <div className="App">
      <Mainroute />
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </div>
  );
}

export default App;
