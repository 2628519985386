import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { addOrUpdateBonus, bonusListApi, getAdminConfigurationApi, updateAdminConfigurationsApi, updateBonusStatus } from '../../../../utils/ApiEndpointFunctions';
import { capitalizeWords, convertTimeTo12HrFormat } from '../../../../utils/HelperFuncitons';
import DeletedModal from '../../../common/Modal/DeletedModal';
import { bonusInitialValue, bonusValidationSchema, minimumChargesBonusInitialValue, minimumChargesBonusInitialValueSchema } from '../../../common/Validation';
import AddBonusFormModal from '../../../common/Modal/AddBonusFormModal';
import MinimumRideChargesForBonusModal from '../../../common/Modal/MinimumRideChargesForBonusModal';
import { useSearchParams } from 'react-router-dom';
import CommonTable from '../../../common/Table/CommonTable';

const BonusTable = ({ categoryType, categoryId }) => {
  const [loader, setLoader] = useState(true);
  const [addLoader, setAddLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const BonusModalId = "BonusModalId"
  const InputWithButtonModalId = "InputWithButtonModalId"
  const deleteModalId = "bonusDeleteModal"
  const [minChargesLoader, setMinChargesLoader] = useState(false);
  

  const getBonusList = async () => {
    try {
      setLoader(true)
      const res = await bonusListApi(categoryId, categoryType)
      if (res.status === 200) {
        setTableData(res.data?.BonusList)
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const deleteBonus = async () => {
    setAddLoader(true)
    try {
      const res = await updateBonusStatus({ ...selectedId, status: false })
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getBonusList();
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const submitMinRideCharges = async(value) => {
    try {
      setMinChargesLoader(true)
      const payload = { minRideChargesForBonus: value.amount }
      const res = await updateAdminConfigurationsApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        document.getElementById(InputWithButtonModalId).click();
      }
    } catch (error) {
      console.log(error);
    }
    setMinChargesLoader(false)
  }

  const handleSubmit = async (values) => {
    try {
      let payload = JSON.parse(JSON.stringify(values));
      setAddLoader(true)
      payload['duration'] = payload.duration.value
      payload.type = categoryType;
      payload.categoryId = categoryId;

      const res = await addOrUpdateBonus(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getBonusList();
        document.getElementById(BonusModalId).click();
        formik.resetForm();
      }

    } catch (error) {
      console.log(error);
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  // const updatePeekHourStatus = async (e) => {
  //   try {
  //     setStatusLoading(true)
  //     const payload = { peakChargesToggle: !peakChargesToggle }
  //     const res = await updateAdminConfigurationsApi(payload)
  //     if (res.status === 200) {
  //       setPeakChargesToggle(res.data?.data?.peakChargesToggle);
  //       toast.success(capitalizeWords(res.data?.message))
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setStatusLoading(false)
  // }


  const formik = useFormik({
    initialValues: bonusInitialValue,
    onSubmit: handleSubmit,
    validationSchema: bonusValidationSchema
  })

  const minimumChargesFormik = useFormik({
    initialValues: minimumChargesBonusInitialValue,
    onSubmit:submitMinRideCharges,
    validationSchema:minimumChargesBonusInitialValueSchema
  })

  const getMinCharges = async () => {
    try {
      const res = await getAdminConfigurationApi()
      if (res.status === 200) {
        minimumChargesFormik.setFieldValue("amount",res.data?.data?.minRideChargesForBonus)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updatingValueHandler = (value) => {
    if (value?.duration) {
      value.duration = {
        label: capitalizeWords(value?.duration),
        value: value?.duration
      };
    }
    if(!value?.from || !value?.to){
      value.from = ""
      value.to = ""
    }
    formik.setValues(value);
  };

  const headerData = [
    { name: "S.No", className: "col-1", key: "index" },
    { name: "Name", className: "col-2", key: "name" },
    { name: "No Of Trips", className: "col-1", key: "noOfTrips" },
    { name: "Duration", className: "col-1", key: "duration" },
    { name: "From", className: "col-1", key: "from" },
    { name: "To", className: "col-1", key: "to" },
    { name: "Minimum Rating", className: "col-1", key: "minRating" },
    { name: "Amount", className: "col-1", key: "amount" },
    { name: "", className: "col-1", key: "action" }
  ];

  const bonusTableData = tableData.map((ele, index) => ({
    index: index + 1,
    name: ele?.name || "-",
    noOfTrips: ele?.noOfTrips || "-",
    duration: ele?.duration && typeof ele.duration === "object" ? (ele?.duration?.label) : ele?.duration ? capitalizeWords(ele.duration) : "",
    from: ele?.from ? convertTimeTo12HrFormat(ele?.from) : "-",
    to: ele?.to ? convertTimeTo12HrFormat(ele?.to) : "-",
    minRating: ele?.minimumRating ?? "-",
    amount: ele?.amount ?? "-",
    action: (<div className="dropdown">
      <button
        className=" border-0 bg-white"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-three-dots-vertical"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
        </svg>
      </button>
      <ul
        className="dropdown-menu"
        style={{ zIndex: "1000" }}
      >
        <li className="dropdown-item">
          <div
            data-bs-toggle="modal"
            data-bs-target={`#${BonusModalId}`}
            onClick={() => { updatingValueHandler(ele) }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </div>
        </li>

        <li className="dropdown-item" >
          <div
            data-bs-toggle="modal"
            data-bs-target={`#${deleteModalId}`}
            onClick={() => { setSelectedId({ id: ele?.id, }) }}
            style={{ cursor: "pointer" }}
          >
            Delete
          </div>
        </li>
      </ul>
    </div>)


  }))
  

  useEffect(() => {
    if (categoryType) {
      if (categoryId) getBonusList();
      else setLoader(false)
    } else {
      getBonusList();
    }
    getMinCharges()
  }, [])
    

  return (
    <div className='card'>
      <div className="card-header">
        <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
          <h1>Bonus</h1>
          <div style={{ display: "-webkit-inline-box" }}>
            <div className="position-relative my-3">
              <div className="d-flex gap-2 justify-content-center align-items-center">
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target={`#${InputWithButtonModalId}`}
                >
                  Minimum Ride Charges
                </button>
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target={`#${BonusModalId}`}
                  onClick={formik.resetForm}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body">
        <CommonTable headers={headerData} data={bonusTableData} loading={loader} />
      </div>
      <AddBonusFormModal modalId={BonusModalId} addLoader={addLoader} formik={formik} />
      <DeletedModal
        modalId={deleteModalId}
        message={"this Bonus"}
        deletedHandler={deleteBonus}
        loader={addLoader}
      />
      <MinimumRideChargesForBonusModal
        modalId={InputWithButtonModalId}
        formik={minimumChargesFormik}
        loader={minChargesLoader}
      />
    </div>
  )

}

export default BonusTable;