import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getCustomerDetailsApi } from '../../../utils/ApiEndpointFunctions';
import { S3_URL } from '../../../utils/BaseUrl'
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import Spinwheel from '../../common/Spinwheel';
import Rides from '../rides/Rides';

function CustomerDetails() {
  const params = useParams();
  const [userDetails,setUserDetails] = useState({})
  const [id, setId] = useState(params?.id)
  const [loader, setLoader] = useState(true)

  const getUserRidesList = async () => {
    try {
      const res = await getCustomerDetailsApi(id)
      if (res.status === 200) {
        const userD = res?.data?.data?.userDetails
        setUserDetails(userD)
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  useEffect(() => {
    getUserRidesList();
  }, [])

  return (
    <>
    <div className="main-content">
      <div className="container-fluid">
        {/* {loader ? (
          <div className="text-center">
            <Spinwheel />
          </div>
        ) : ( */}
          {!loader && <>
            <div className="row align-items-end">
              <div className="col-auto">
                <div className="avatar avatar-xxl header-avatar-top mt-3">
                  <img src={userDetails?.profileImage ? `${S3_URL}/${userDetails?.profileImage}` : "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"} alt="profile" className="avatar-img rounded-circle border border-4 border-body" />
                </div>
              </div>
              <div className="col mb-3 ms-n3 ms-md-n2">
                <h1 className="header-title">
                    {userDetails.firstName ? capitalizeWords(userDetails.firstName + ' ' + userDetails.lastName):"-"}
                </h1>
              </div>
            </div>

          </>}
        {/* )} */}


      </div>
    </div>
      <Rides userId={id} type={"customerDetails"}/>
    </>

  )
}

export default CustomerDetails