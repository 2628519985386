import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { addOrUpdateDefaultCategoryApi, getDefaultCategoryListApi, updateDefaultCategoryApi } from '../../../../utils/ApiEndpointFunctions'
import { capitalizeWords } from '../../../../utils/HelperFuncitons'
import DeletedModal from '../../../common/Modal/DeletedModal'
import { categoryInitialValue, defaultCategoryValidationSchema } from '../../../common/Validation'
import { S3_URL } from '../../../../utils/BaseUrl'
import ImageViewModal from '../../../common/Modal/ImageViewModal'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import CommonTable from '../../../common/Table/CommonTable'

function DefaultCategoryRental() {
    const [loader, setLoader] = useState(true);
    const [addLoader, setAddLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const [selectedImg, setSelectedImg] = useState("")
    const DefaultCategoryModalId = "DefaultCategoryModal"
    const deleteModalId = "categoryDeleteModal"
    const imageViewModalId = "imageViewModalId"
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const rentalVehicleType = searchParams?.get("tab")

    const getAllCategory = async () => {
        try {
            setLoader(true)
            const res = await getDefaultCategoryListApi("yiinoRental", rentalVehicleType)
            if (res.status === 200) {
                setTableData(res.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
        setLoader(false)
    }

    const handleSubmit = async (values) => {
        try {
            setAddLoader(true)
            let payload = JSON.parse(JSON.stringify(values));
            const res = await addOrUpdateDefaultCategoryApi(payload)
            if (res.status === 200) {
                toast.success(capitalizeWords(res.data?.message))
                getAllCategory();
                document.getElementById(DefaultCategoryModalId).click();
                formik.resetForm();
            }
        } catch (error) {
            error.response ?
                toast.error(error.response?.data?.message) :
                toast.error("Something went wrong");
            console.log(error)
        }
        setAddLoader(false)
    }

    const deleteCategory = async () => {
        setAddLoader(true)
        try {
            const res = await updateDefaultCategoryApi({ id: selectedId, status: false })
            if (res.status === 200) {
                document.getElementById(deleteModalId).click();
                toast.success(capitalizeWords(res.data?.message))
                getAllCategory();
            }
        } catch (error) {
            console.log(error)
            error.response ?
                toast.error(error.response?.data?.message) :
                toast.error("Something went wrong");
        }
        setAddLoader(false)
    }

    const formik = useFormik({
        initialValues: categoryInitialValue,
        onSubmit: handleSubmit,
        validationSchema: defaultCategoryValidationSchema
    })

    const updatingValueHandler = (value) => {
        formik.setValues(value)
    };

    useEffect(() => {
        if(rentalVehicleType)
        getAllCategory();
    }, [rentalVehicleType])

    const headerData = [
        { name: "S.No.", className: "col", key: "index" },
        { name: "Category", className: "col", key: "category" },
        // { name: "Surge", className:"col", key:"surcharge" },
        { name: "Cancellation", className: "col", key: "cancellationFee" },
        { name: "Platform Fee(%)", className: "col", key: "platformFee" },
        { name: "Action", className: "col", key: "action" },
    ]

    const defaultCategoryTableData = tableData?.map((ele, index) => ({
        index: index + 1,
        category: (<>
            {ele.image && <div className="avatar avatar-xs align-middle me-2">
                <img
                    className="avatar-img rounded-circle"
                    src={!ele.image ? "/images/profiledp.png" : `${S3_URL}/${ele?.image}`}
                    alt="img"
                    data-bs-toggle="modal"
                    data-bs-target={`#${imageViewModalId}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => { setSelectedImg(!ele.image ? "/images/profiledp.png" : `${S3_URL}/${ele?.image}`) }}
                />
            </div>}
            <Link className='text-primary' to={`/partners/rentals/defaultCategory?op=update&id=${ele?.id}&rentalVehicleType=${rentalVehicleType}`}>{capitalizeWords(ele?.category)}</Link>
        </>),
        // surcharge: ele?.surcharge ?? "-",
        cancellationFee: ele?.cancellationFee ?? "-",
        platformFee: ele?.platformFee ?? "-",
        action: (
            <>
                <div className="dropdown">
                    <button
                        className=" border-0 bg-white"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-three-dots-vertical"
                            viewBox="0 0 16 16"
                        >
                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                    </button>
                    <ul
                        className="dropdown-menu"
                        style={{ zIndex: "1000" }}
                    >
                        <li className="dropdown-item">
                            <div
                                // data-bs-toggle="modal"
                                // data-bs-target={`#${DefaultCategoryModalId}`}
                                // onClick={() => { updatingValueHandler(ele) }}
                                onClick={() => { navigate(`/partners/rentals/defaultCategory?op=update&id=${ele?.id}&rentalVehicleType=${rentalVehicleType}`) }}
                                style={{ cursor: "pointer" }}
                            >
                                View / Edit
                            </div>
                        </li>

                        <li className="dropdown-item" >
                            <div
                                data-bs-toggle="modal"
                                data-bs-target={`#${deleteModalId}`}
                                onClick={() => { setSelectedId(ele?.id) }}
                                style={{ cursor: "pointer" }}
                            >
                                Delete
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        )
    }))

    return (
        <><div className="d-flex justify-content-end flex-wrap align-items-center mt-3 mt-lg-0">
            <div style={{ display: "-webkit-inline-box" }}>
                <div className="position-relative my-3">

                    <button
                        className="btn btn-primary"
                        // data-bs-toggle="modal"
                        // data-bs-target={`#${DefaultCategoryModalId}`}
                        // onClick={() => { formik.resetForm(); }}
                        onClick={() => { navigate(`/partners/rentals/defaultCategory?op=add&rentalVehicleType=${rentalVehicleType}&rentalVehicleType=${rentalVehicleType}`) }}
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
            <CommonTable headers={headerData} data={defaultCategoryTableData} loading={loader} />

            <DeletedModal
                modalId={deleteModalId}
                message={"this Category"}
                deletedHandler={deleteCategory}
                loader={addLoader}
            />
            <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />
        </>
    )
}

export default DefaultCategoryRental;