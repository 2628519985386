import React from 'react'
import AsyncSelect from 'react-select/async';
import Spinwheel from '../Spinwheel'
import { debounce } from 'lodash';
import { citySuggestionsApi } from '../../../utils/ApiEndpointFunctions';

function CountryAndCityModal({ formik, loader, modalId, type, selectedCountry }) {
  // console.log(selectedCountry)

  const filterCites = async (inputValue, cb) => {
    try {
      const res = type === "City" ? await citySuggestionsApi(inputValue, selectedCountry?.countryISOCode) : "Contry"
      if (res?.status === 200) {
        // console.log(res.data)
        let cityOption = res.data?.data?.map(ele => {
          return { label: ele.description, value: ele }
        })
        // console.log(cityOption)
        cb(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const debouncedOnChange = debounce(filterCites, 1000);

  const dropDownOptions = (inputValue, callback) => {
    debouncedOnChange(inputValue, callback);
  }



  return (
    <>

      <div
        className={`modal fade `}
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      // style={{display:status ? "none" : "inline"}}
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>{type}</h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className='mb-3' >
                    <AsyncSelect cacheOptions loadOptions={dropDownOptions} defaultOptions value={formik?.values?.name ? { label: formik?.values?.name?.description, value: formik?.values?.name } : null} onChange={(el) => { formik?.setFieldValue("name", el.value) }} onBlur={formik?.handleBlur} />
                    {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                  </div>
                  <button type="submit" disabled={loader} className="btn btn-primary">
                    {loader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CountryAndCityModal