import React from 'react'
import Spinwheel from '../Spinwheel'
import Select from 'react-select';

function AddSubscriptionDriverModal(props) {
    const { formik, addLoader, modalId, options } = props

    const durationOptions = options ? Object.keys(options).map(el => ({ label: options[el], value: el })) : []

    return (
        <>
            <div
                className="modal fade"
                id={modalId}
                tabIndex="-1"
                aria-labelledby="deleteModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button
                                id="confirmModalCloseBtn"
                                type="button"
                                className="btn-close block-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body pe-0 ps-0">
                            <div className="container">
                                <h2>Add Subscription</h2>
                                <form onSubmit={formik?.handleSubmit} >

                                    <div className='row '>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Enter period name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                                            {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="duration" className="form-label">Duration</label>
                                            <Select
                                                id="duration"
                                                name="duration"
                                                options={durationOptions}
                                                value={formik.values.duration}
                                                onChange={(selectedOption) =>
                                                    formik.setFieldValue('duration', selectedOption)
                                                }
                                                onBlur={() => formik.setFieldTouched('duration', true)}
                                            />
                                            {formik.touched.duration && formik.errors.duration ? (
                                                <div className='req-error'>{formik.errors.duration.label}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="amount" className="form-label">Amount</label>
                                        <input type="number" className="form-control" id="amount" placeholder="Enter period name" name='amount' value={formik?.values?.amount} onChange={formik?.handleChange} onBlur={formik?.handleBlur} onWheel={(e) => e.target.blur()} />
                                        {formik?.touched?.amount && formik?.errors?.amount ? (<div className='req-error'>{formik?.errors?.amount}</div>) : null}
                                    </div>
                                    <button type="submit" disabled={addLoader} className="btn btn-primary">
                                        {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddSubscriptionDriverModal