import React, { useState } from "react";
import { useFormik } from "formik";
import {toast} from "react-hot-toast";
import { Link } from "react-router-dom";
import { adminInitial, adminValidationSchema } from "../common/Validation";
import { loginAdmin } from "../../utils/ApiEndpointFunctions";
import  Spinwheel from "../common/Spinwheel"


const Login = () => {

   
const [loader, setLoader] = useState(false);
const [admin, setAdmin] = useState({checked : false, email: "", password: ""});
const [hideShow, setHideShow] = useState("password")

// const navigate = useNavigate()

const ShowHide = ()=>{
    if(hideShow === "password"){
        setHideShow("text")
    }else{
        setHideShow("password")
    }
}
 
const handleSubmit = async (values) => {

       const payload =  {
        email: values.email,
        password: values.password } 
        try{

            setLoader(true)
            const res = await loginAdmin(payload);
            if (res?.status === 200 && res?.data?.status === 1) {
                toast.success("User Login successfully.")
                localStorage.setItem("token", res?.data?.token);
                window.location = "/dashboard"
                setLoader(false)
            } 

        }catch(error){
            error.response ?
                toast.error(error.response?.data?.message) :
                toast.error("Something went wrong");
            setLoader(false)
        }
    
    }

const formik = useFormik({
    initialValues: adminInitial,
    onSubmit: handleSubmit,
    validationSchema: adminValidationSchema
})




return (
    <>
        <main>
            <section className="">
                <div className="container-fluid">
                    <div className="row">
                       
                        <div className="col-xl-3 col-lg-5 col-md-7 col-sm-12 mx-auto">
                            <div className="d-grid text-center">
                                <div className="d-flex justify-content-center">
                                <img
                                            src={"/images/appLogo_new.png"}
                                            alt="logo"
                                            height={200}
                                            // width={200}
                                            className="mt-2"
                                        />
                                </div>
                                <br />
                                <br />
                                <h2 className="sign-in">Sign In</h2>
                                <p>Sign in to your admin account</p>
                                <br />
                                <form action onSubmit={formik.handleSubmit}>
                                    <label
                                        className="form-label text-start d-block"
                                        htmlFor="email"
                                    >
                                        Email address
                                    </label>
                                    <div className="position-relative">
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="@example.com"
                                            value={admin?.checked === true ? formik.values.email=admin?.email : formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                          {formik.touched.email && formik.errors.email ? (<div className='req-error'>{formik.errors.email}</div>) : null}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-envelope-fill input-icon"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                        </svg>
                                    </div>
                                    <br />
                                    <label
                                        className="form-label text-start d-block"
                                        htmlFor="pass"
                                    >
                                        Enter a Password
                                    </label>
                                    <div className="position-relative">
                                        <input
                                            className="form-control"
                                            type={hideShow}
                                            name="password"
                                            id="pass"
                                            placeholder="Enter your password"
                                            value={admin?.checked === true ? formik.values.password=admin?.password : formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                         {formik.touched.password && formik.errors.password ? (<div className='req-error'>{formik.errors.password}</div>) : null}
                                        {/* <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-eye-fill input-icon d-none"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                        </svg> */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className={hideShow==="password"?"bi bi-eye-slash-fill input-icon":"bi bi-eye-fill input-icon"}
                                            viewBox="0 0 16 16"
                                            onClick={ShowHide}
                                        >
                                            {hideShow==="text"? <><path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                            </>    :<>
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" /></>}
                                        </svg>
                                    </div>
                                    <br />
                                    {/* <div className="d-flex justify-content-end">
                                        <div>
                                             <input type="checkbox" name id="Remember" checked={admin?.checked}
                                             onClick={(e)=>{admin?.checked === false ? setAdmin({checked: true, email: "admin@yiino.com", password:"1234" }) : setAdmin({checked: false, email: "", password:"" })}} 
                                             />{" "}
                                            <label htmlFor="Remember"> Remember me</label>
                                        </div>
                                        <div>
                                            <Link
                                                to="#"
                                                className="text-decoration-underline "
                                            >
                                                Forgot Password?
                                            </Link>
                                        </div>
                                    </div> */}
                                    {/* <br /> */}
                                    <br />
                                    <button type= "submit" className="btn-custom">{loader === true ? <Spinwheel sizeClass="spin-small"  /> : "SIGN IN"}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
);
};

export default Login;