import React, { useEffect, useState } from 'react'
import { countryListApi } from '../../../utils/ApiEndpointFunctions';
import Select from 'react-select';
import Spinwheel from '../Spinwheel';

function AddAdminModal({ formik, modalId, loader }) {
  // const [hideShow, setHideShow] = useState("password")
  const [countryOptions, setCountryOptions] = useState([])

  // const ShowHide = () => {
  //   if (hideShow === "password") {
  //     setHideShow("text")
  //   } else {
  //     setHideShow("password")
  //   }
  // }

  const getCountry = async (inputValue) => {
    try {
      const res = await countryListApi(inputValue)
      if (res.status === 200) {
        let cityOption = res.data?.data?.map(ele => ({ label: ele.name, value: ele.id }))
        setCountryOptions(cityOption);
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getCountry()
  }, [])


  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>Add Admin</h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className='row '>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">First Name</label>
                      <input type="text" className="form-control" id="firstName" placeholder="Enter first name" name='firstName' value={formik?.values?.firstName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.firstName && formik?.errors?.firstName ? (<div className='req-error'>{formik?.errors?.firstName}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Last Name</label>
                      <input type="text" className="form-control" id="lastName" placeholder="Enter last name" name='lastName' value={formik?.values?.lastName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                      {formik?.touched?.lastName && formik?.errors?.lastName ? (<div className='req-error'>{formik?.errors?.lastName}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="text" className="form-control" id="email" placeholder="Enter email" name='email' value={formik?.values?.email} onChange={formik?.handleChange} onBlur={formik?.handleBlur} autoComplete='off'/>
                      {formik?.touched?.email && formik?.errors?.email ? (<div className='req-error'>{formik?.errors?.email}</div>) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Password</label>
                      {/* <div className='position-relative'> */}

                        <input type="text" className="form-control" id="password" placeholder="Enter password" name='password' value={formik?.values?.password} onChange={formik?.handleChange} onBlur={formik?.handleBlur} autoComplete='off' />
                        {formik?.touched?.password && formik?.errors?.password ? (<div className='req-error'>{formik?.errors?.password}</div>) : null}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className={hideShow === "password" ? "bi bi-eye-slash-fill input-icon" : "bi bi-eye-fill input-icon"}
                          viewBox="0 0 16 16"
                          onClick={ShowHide}
                        >
                          {hideShow === "text" ? <><path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                          </> : <>
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" /></>}
                        </svg> */}
                      {/* </div> */}

                    </div>

                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">country</label>
                      <Select
                        id="country"
                        name="country"
                        options={countryOptions}
                        value={formik.values.country}
                        onChange={(selectedOption) =>
                          formik.setFieldValue('country', selectedOption)
                        }
                        onBlur={() => formik.setFieldTouched('country', true)}
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <div className='req-error'>{formik.errors.country.label}</div>
                      ) : null}
                    </div>
                  </div>
                  <button type="submit" disabled={loader} className="btn btn-primary">
                    {loader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddAdminModal