import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminChangePartnerApprovalStatus, approveOrRejectRestaurantDocApi, editRestaurantApi,  restaurantById } from '../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../common/Spinwheel';
import { capitalizeWords, dateFormat } from '../../../utils/HelperFuncitons';
import { S3_URL } from '../../../utils/BaseUrl';
import ConfirmationModal from '../../common/ConfirmationModal';
import PdfSvgIcon from '../../common/PdfSvgIcon';
import ImageViewModal from '../../common/Modal/ImageViewModal';
import toast from 'react-hot-toast';
import CommonTable from '../../common/Table/CommonTable';
import { useFormik } from 'formik';
import { rateRestaurantInitialValues, rateRestaurantValidationSchema } from '../../common/Validation';

const RestaurentDetails = (props) => {
  const params = useParams();
  const [id, setId] = useState(params?.id)
  const [profileDetails, setProfiledetails] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [restaurantDetails, setRestaurantDetails] = useState({});
  const [restaurantDocument, setRestaurantDocument] = useState({});
  const [selectDocument, setSelectDocument] = useState({ status: "", keyName: "", validDate: "", restaurantId :""});
  const [bankDetails, setBankDetails] = useState([]);
  const [rateLoader, setRateLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [activeTab, setActiveTab] = useState('driver');
  const [confirmStatus, setConfirmStatus] = useState({ status: "", userId: "", role: "restaurant" });
  const [selectedImg, setSelectedImg] = useState("")
  const confirmationModalId2 = "confirmationModal2"

  const fetchDriverDetails = async () => {
    try {
      const res = await restaurantById(id);
      if (res.status === 200) {
        const { vehicles, details } = res.data.data;
        const { id, role, phone, restaurant, restaurantDocument, bankDetails, partnerUserDetails } = details       
        setRestaurantDocument(restaurantDocument)
        setProfiledetails({ id, phone, role, ...partnerUserDetails[0], vendorStatus: restaurant.status });
        setVehicleDetails(vehicles);
        setRestaurantDetails(restaurant)
        setBankDetails(bankDetails)
        rateFormik.setValues({ id: id, deliveryFee: restaurant.deliveryFee, tax: restaurant.tax, discountOnOff: restaurant.discountOnOff, discount: restaurant.discount });

      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const handleChangeStatus = async () => {
    try {
      setLoader(true)
      await adminChangePartnerApprovalStatus(confirmStatus)
      fetchDriverDetails();
      document.getElementById('confirmationModal').click()
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something Went Wrong")
      setLoader(false)
    }
  }

  const openModal = () => {
    document.getElementById('confirmationModal2Btn').click()
  }



  useEffect(() => {
    if (id) {
      fetchDriverDetails();
    }
  }, [id]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleChangeDocumentStatus = async () => {
    const promise = approveOrRejectRestaurantDocApi({ userId: id, keyName: selectDocument.keyName, status: selectDocument.status, restaurantId: selectDocument.restaurantId });
    toast.promise(
      promise,
      {
        loading: 'Updating document status...',
        success: (res) => {
          if (res.status === 200) {
            profileDetails.vendorStatus = res.data.restaurantStatus ? res.data.restaurantStatus : profileDetails.vendorStatus
            setRestaurantDocument({ ...restaurantDocument, [selectDocument.keyName] : res.data.docStatus})
            return res.data.message || 'Success';
          }
        },
        error: (err) => {
          return err.message || 'Something Went Wrong'
        }
      }
    )
  };

  const DocumentDisplay = ({ title, document, setSelectedImg, status, keyName, validUpto, number, restaurantId }) => {
   
    if (!document) {
      return (
        <tr>
          <td>{title}</td>
          <td className='text-center'>{`${title} not available`}</td>
        </tr>
      );
    }

    const isPdf = document ? document.split(".").slice(-1)[0] === "pdf" : "";

    return (
      <tr>
        <td>{title}</td>
        <td>{number}</td>
        <td className='text-center'>
          {isPdf ? (
            <Link to={`${S3_URL}/${document}`} className="text-primary">
              <PdfSvgIcon width="100" height="100" />
            </Link>
          ) : (
            <img
              src={`${S3_URL}/${document}`}
              alt={title}
              data-bs-toggle="modal"
              data-bs-target={`#imageViewModal`}
              style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
              onClick={() => setSelectedImg(`${S3_URL}/${document}`)}
            />
          )}
        </td>
        <td className='text-center'>{validUpto ? dateFormat(validUpto) : ""}</td>
        <td className='text-center'>{capitalizeWords(status) || "-"}</td>
        {status !== "pending" && <td className='text-center'>
          <div className="dropdown">
            <button
              className=" border-0 bg-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-three-dots-vertical"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </button>
            <ul
              className="dropdown-menu"
              style={{ zIndex: "1000" }}
            >
              {(status === "rejected" || status === "submitted") && <li
                onClick={() => {
                  
                  setSelectDocument({ status: 'approved', keyName: keyName, validDate: validUpto, restaurantId: restaurantId })
                    openModal()
                }}
              >
                <a className="dropdown-item" href="#">
                  Approve
                </a>
              </li>}

              {(status === "approved" || status === "submitted") && <li
                onClick={() =>{
                  setSelectDocument({ status: 'rejected', keyName: keyName, validDate: validUpto, restaurantId: restaurantId })
                  openModal()
                }}
              >
                <a className="dropdown-item" href="#">
                  Reject
                </a>
              </li>}
            </ul>
          </div>
        </td>}
      </tr>
    );
  };



  const vehicleHeaderData = [
    // { name: "Registration Number", className: "list-sort text-muted", key: "licenseNo" },
    { name: "Year", className: "list-sort text-muted", key: "year" },
    { name: "Class", className: "list-sort text-muted", key: "category" },
    { name: "Type", className: "list-sort text-muted", key: "make" },
    { name: "Model", className: "list-sort text-muted", key: "model" },
    { name: "Color", className: "list-sort text-muted", key: "color" },
    { name: "Registration Number", className: "list-sort text-muted", key: "vehicleNumber" },
    { name: "Vehicle Certificate", className: "list-sort text-muted", key: "vehicleCertificate" },
    { name: "Insurance", className: "list-sort text-muted", key: "vehicleInsurance" },

  ]

  const vehicleTableData = vehicleDetails?.map((vhcl, index) => {
    // console.log(vhcl)
    return {
      licenseNo: vhcl.licenseNo,
      year: vhcl.year,
      category: vhcl?.category,
      make: vhcl?.make,
      model: vhcl?.model,
      color: capitalizeWords(vhcl?.color) || "",
      vehicleNumber: vhcl?.vehicleNumber || "",
      vehicleCertificate: vhcl?.vehicleCertificate?.split(".")?.slice(-1)?.includes("pdf") ?
        <Link to={`${S3_URL}/${vhcl?.vehicleCertificate}`}  > <PdfSvgIcon width="100" height="100" /></Link>
        :
        <img
          src={vhcl?.vehicleCertificate ? `${S3_URL}/${vhcl?.vehicleCertificate}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
          alt={"img"}
          data-bs-toggle="modal"
          data-bs-target={`#imageViewModal`}
          style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
          onClick={() => { setSelectedImg(vhcl?.vehicleCertificate ? `${S3_URL}/${vhcl?.vehicleCertificate}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
        />,
      vehicleInsurance: vhcl?.vehicleInsurance?.split(".")?.slice(-1)?.includes("pdf") ?
        <Link to={`${S3_URL}/${vhcl?.vehicleInsurance}`}> <PdfSvgIcon width="100" height="100" /></Link>
        :
        <img
          src={vhcl?.vehicleInsurance ? `${S3_URL}/${vhcl?.vehicleInsurance}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
          alt={"img"}
          data-bs-toggle="modal"
          data-bs-target={`#imageViewModal`}
          style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
          onClick={() => { setSelectedImg(vhcl?.vehicleInsurance ? `${S3_URL}/${vhcl?.vehicleInsurance}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
        />
    }
  })


  const bankDetailsHeaderData = [
    { name: "Account Holder Namer", className: "list-sort text-muted", key: "name" },
    { name: "Bank Name", className: "list-sort text-muted", key: "bankName" },
    { name: "IFSC", className: "list-sort text-muted", key: "Ifsc" },
    { name: "Account Number", className: "list-sort text-muted", key: "accountNo" },
    { name: "Account Type", className: "list-sort text-muted", key: "accountType" },

  ]

  const bankDetailsTableData = bankDetails?.map((bnk, index) => ({
    name: profileDetails.name || "",
    bankName: bnk.bankName || "",
    Ifsc: bnk.Ifsc || "",
    accountNo: bnk.accountNo || "",
    accountType: bnk.accountType || "",
  }))

  const profileData = [
    { name: "Email", data: profileDetails.email, },
    { name: "Phone", data: profileDetails.phone, },
    { name: "Alternate Phone", data: profileDetails.alternateMobile, },
    { name: "NIN", data: profileDetails.NIN, },
    { name: "Status", data: capitalizeWords(profileDetails.vendorStatus), },
    { name: "Role", data: profileDetails.role?.map(el => capitalizeWords(el))?.join(", "), },
    { name: "Gender", data: profileDetails.gender, },
    { name: "City", data: profileDetails.city, },
    { name: "State", data: profileDetails.state, },
    { name: "Country", data: profileDetails.country, },
    { name: "Zip Code", data: profileDetails.zip, },
    { name: "Address", data: <div>{profileDetails.permanentAddress && `${profileDetails.permanentAddress}, `}{profileDetails.state && `${profileDetails.state}, `}{profileDetails.city && `${profileDetails.city}, `}{profileDetails.zip && `${profileDetails.zip}`}</div>, },

  ]


  const restaurant = [
    { name: "Address", data: restaurantDetails?.address },
    { name: "Closing Time", data: restaurantDetails?.closingTime },
    { name: "Opening Time", data: restaurantDetails?.openingTime },
    { name: "Contact No", data: restaurantDetails?.contactNo },
    { name: "Days", data: restaurantDetails?.days?.join(", ") },
    { name: "Delivery Restaurant Timing Type", data: restaurantDetails?.deliveryRestaurantTimingType ? "Delivery and restaurant timings are same" : "Delivery timing are different from restaurant timings" },
    { name: "Establishment Type", data: restaurantDetails?.establishmentType },
    { name: "Online", data: restaurantDetails?.isOnline ? "Online" : "Offline" },
    { name: "Name", data: restaurantDetails?.name },
    { name: "Secondary Contact No", data: restaurantDetails?.secondarycontactNo },
    { name: "Secondary Contact No Type", data: restaurantDetails?.secondarycontactNoType },
    { name: "Status", data: restaurantDetails?.status },
    {
      name: "Menu Image",
      data: restaurantDetails?.menuImage?.split(".")?.slice(- 1)?.includes("pdf") ?
        <Link to={`${S3_URL}/${restaurantDetails?.menuImage}`}  > <PdfSvgIcon width="100" height="100" /></Link>
        :
        <img
          src={restaurantDetails?.menuImage ? `${S3_URL}/${restaurantDetails?.menuImage}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
          alt={"img"}
          data-bs-toggle="modal"
          data-bs-target={`#imageViewModal`}
          style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
          onClick={() => { setSelectedImg(restaurantDetails?.menuImage ? `${S3_URL}/${restaurantDetails?.menuImage}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
        />,
    },
    {
      name: "Menu Image For Online Orders",
      data: restaurantDetails?.menuImageForOnlineOrders?.split(".")?.slice(- 1)?.includes("pdf") ?
        <Link to={`${S3_URL}/${restaurantDetails?.menuImageForOnlineOrders}`}  > <PdfSvgIcon width="100" height="100" /></Link>
        :
        <img
          src={restaurantDetails?.menuImageForOnlineOrders ? `${S3_URL}/${restaurantDetails?.menuImageForOnlineOrders}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
          alt={"img"}
          data-bs-toggle="modal"
          data-bs-target={`#imageViewModal`}
          style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
          onClick={() => { setSelectedImg(restaurantDetails?.menuImageForOnlineOrders ? `${S3_URL}/${restaurantDetails?.menuImageForOnlineOrders}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
        />,
    },
    {
      name: "Restaurant Image",
      data: restaurantDetails?.restaurantImage?.split(".")?.slice(- 1)?.includes("pdf") ?
        <Link to={`${S3_URL}/${restaurantDetails?.restaurantImage}`}  > <PdfSvgIcon width="100" height="100" /></Link>
        :
        <img
          src={restaurantDetails?.restaurantImage ? `${S3_URL}/${restaurantDetails?.restaurantImage}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg"}
          alt={"img"}
          data-bs-toggle="modal"
          data-bs-target={`#imageViewModal`}
          style={{ cursor: 'pointer', maxWidth: '100%', maxHeight: '100px', borderRadius: "8%" }}
          onClick={() => { setSelectedImg(restaurantDetails?.restaurantImage ? `${S3_URL}/${restaurantDetails?.restaurantImage}` : "https://www.team-bhp.com/sites/default/files/pictures2021/2.jpg") }}
        />,
    },
  ]

  const rateSubmit = async (values) => {
    setRateLoader(true)
    try {
      const res = await editRestaurantApi(values)
      if (res.status === 200) {

        toast.success(res.data.message)
      }
    } catch (error) {
      toast.error("Failed to update rate")

    }

    setRateLoader(false)

    console.log(values)
  }

  const rateFormik = useFormik({
    initialValues: rateRestaurantInitialValues,
    validationSchema: rateRestaurantValidationSchema,
    onSubmit: rateSubmit
  })



  const tabData = [
    {
      name: "Profile Details", tab: "driver", setTab: toggleTab, data: <div className="row card">
        <div className="row mt-3">
          <div className="user-details">
            <table className="table table-md table-hover card-table">
              <tbody>
                {
                  profileData.map((ele, index) => (
                    <tr key={index}>
                      <td>{ele.name}:</td>
                      <td>{ele.data}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    },
    {
      name: "Restaurant Details", tab: "vehicle", setTab: toggleTab, data: <div className="row card">
        <div className="row mt-3">
          <div className="user-details">
            <table className="table table-md table-hover card-table">
              <tbody>
                {
                  restaurant?.map((ele, index) => (
                    <tr key={index}>
                      <td>{ele.name}:</td>
                      <td>{ele.data}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    },
    { name: "Bank Details", tab: "bankDetails", setTab: toggleTab, data: <div className='p-3 card'><CommonTable headers={bankDetailsHeaderData} data={bankDetailsTableData} /></div> },
    {
      name: "Documents", tab: "document", setTab: toggleTab, data: <div className="row card ">
        <div className="mt-3 table-responsive  " style={{ overflowX: "inherit" }}>
          <table className="table table-md table-hover card-table">
            <thead>
              <tr>
                <th> <span className="list-sort text-muted">Type</span></th>
                <th> <span className="list-sort text-muted">Number</span></th>
                <th className='text-center'> <span className="list-sort text-muted">File</span></th>
                <th className='text-center'> <span className="list-sort text-muted">Valid Upto</span></th>
                <th className='text-center'> <span className="list-sort text-muted">Status</span></th>
                <th className='text-center'> <span className="list-sort text-muted">Action</span></th>
              </tr>
            </thead>
            <tbody>
              <DocumentDisplay
                title="Pan card"
                document={restaurantDocument?.panImage}
                setSelectedImg={setSelectedImg}
                status={restaurantDocument?.panApprovalStatus}
                keyName={"panApprovalStatus"}
                number={restaurantDocument?.panNumber}
                restaurantId={restaurantDocument?.restaurantId}
              />

              {restaurantDocument?.gstCertificate && (
                <DocumentDisplay
                  title="GST"
                  document={restaurantDocument?.gstCertificate}
                  setSelectedImg={setSelectedImg}
                  status={restaurantDocument?.gstApprovalStatus}
                  keyName={"gstApprovalStatus"}
                  number={restaurantDocument?.gstNumber}
                  restaurantId={restaurantDocument?.restaurantId}

                />
              )}
              <DocumentDisplay
                title="FSSAI Certificate"
                document={restaurantDocument?.FSSAICertificate}
                setSelectedImg={setSelectedImg}
                status={restaurantDocument?.fssaiApprovalStatus}
                keyName={"fssaiApprovalStatus"}
                validUpto={restaurantDocument?.FSSAIExpiredDate}
                number={restaurantDocument?.FSSAICertificateNo}
                restaurantId={restaurantDocument?.restaurantId}

              />
            </tbody>
          </table>
        </div>
      </div>
    },
    {
      name: "Rate", tab: "rate", setTab: toggleTab, data:
        <div className="card">
          <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
            Rate
          </div>
          <div className="card-body" >
            <div className='p-5'>
              <form onSubmit={rateFormik.handleSubmit}>
                <div className="form-group row row-cols-4">

                  {/* <div className='d-flex gap-3'>
                    <label htmlFor='deliveryFee' className=" col-form-label">Delivery fee</label>
                    <div className="">
                      <input type="text" className="form-control" id='deliveryFee' name='deliveryFee' placeholder="0" onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values.deliveryFee} />
                      {rateFormik.touched?.deliveryFee && rateFormik.errors?.deliveryFee ? (<div className='req-error'>{rateFormik?.errors?.deliveryFee}</div>) : null}
                    </div>
                  </div> */}

                  <div className='d-flex gap-3'>
                    <label htmlFor='tax' className=" col-form-label">Commission(%)</label>
                    <div className="">
                      <input type="text" className="form-control" id='tax' placeholder="0" name='tax' onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values.tax} />
                      {rateFormik.touched?.tax && rateFormik.errors?.tax ? (<div className='req-error'>{rateFormik?.errors?.tax}</div>) : null}
                    </div>
                  </div>

                  <div className='d-flex gap-3'>
                    <label htmlFor='discount' className=" col-form-label">Discount(%)</label>
                    <div className="">
                      <input type="text" className="form-control" id='discount' placeholder="0" name='discount' onChange={rateFormik.handleChange} onBlur={rateFormik.handleBlur} value={rateFormik.values.discount} />
                      {rateFormik.touched?.discount && rateFormik.errors?.discount ? (<div className='req-error'>{rateFormik?.errors?.discount}</div>) : null}
                    </div>
                  </div>

                  <div className='d-flex align-items-center gap-3'>
                    <label htmlFor='descountOnOff' className=" col-form-label">Discount(on/off)</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" checked={rateFormik?.values?.discountOnOff} id="discountOnOff" onChange={() => rateFormik?.setFieldValue("discountOnOff", !Boolean(rateFormik?.values?.discountOnOff))} />
                      {rateFormik.touched?.discountOnOff && rateFormik.errors?.discountOnOff ? (<div className='req-error'>{rateFormik?.errors?.discountOnOff}</div>) : null}
                    </div>
                  </div>
                  <div className="d-flex align-items-end"  >
                    <button type="submit" className="btn btn-sm btn-primary" disabled={rateLoader}>Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
    },
  ]

  return (
    <div className="main-content">
      <div className="container-fluid">

        {loader ? (
          <div className="text-center">
            <Spinwheel />
          </div>
        ) : (
          <>
            <div className="row align-items-end">
              <div className="col-auto">
                <div className="avatar avatar-xxl header-avatar-top mt-3">
                  <img src={profileDetails?.profileImage ? `${S3_URL}/${profileDetails?.profileImage}` : "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"} alt="profile" className="avatar-img rounded-circle border border-4 border-body" />
                </div>
              </div>
              <div className="col mb-3 ms-n3 ms-md-n2">
                <h1 className="header-title">
                  {capitalizeWords(profileDetails.name)}
                </h1>
              </div>
              <div className="col-12 col-md-auto mt-2 mt-md-0 mb-md-3">
                {(profileDetails.vendorStatus === "pending") ? <><button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal"
                  data-bs-target={`#confirmationModal`}
                  onClick={(e) => { setConfirmStatus(prevState => ({ ...prevState, status: "approved", userId: id })) }}>Approve</button>
                  <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal"
                    data-bs-target={`#confirmationModal`}
                    onClick={(e) => { setConfirmStatus(prevState => ({ ...prevState, status: "rejected", userId: id })) }}>Reject</button></> :
                  <div className={`badge fs-4  ${profileDetails.vendorStatus === "approved" ? "bg-primary-soft" : "bg-secondary-soft"}`}>{capitalizeWords(profileDetails.vendorStatus)}</div>
                }
              </div>
            </div>

            <div className='d-flex justify-content-between'>
              <ul className="nav nav-tabs">
                {
                  tabData.map((ele, index) => (
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === ele.tab ? 'active' : ''}`}
                        onClick={() => ele.setTab(ele.tab)}
                      >
                        {ele.name}
                      </button>
                    </li>
                  ))
                }
              </ul>
              <div>
              </div>
            </div>

            {
              tabData.map((ele) => (
                activeTab === ele.tab ? ele.data : null
              ))
            }
          </>
        )}

        {<ConfirmationModal
          handleConfirm={handleChangeStatus}
          confirmStatus={confirmStatus}
        />}
        <ImageViewModal imgUrl={selectedImg} modalId={"imageViewModal"} />
        <button id="confirmationModal2Btn" className='d-none' data-bs-toggle="modal" data-bs-target={`#${confirmationModalId2}`}></button>
        <ConfirmationModal modalId={confirmationModalId2} message={<> Do you want to continue?</>} handleConfirm={() => handleChangeDocumentStatus()} title={"Warning!"} confirmStatus={""} />
      </div>
    </div>

  );
};

export default RestaurentDetails;
