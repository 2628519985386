import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useAdminContext } from '../../../context/adminContext';
import { changePartnerEarningWithdrawRequestStatusApi, partnerEarningWithdrawRequestApi } from '../../../utils/ApiEndpointFunctions';
import { capitalizeWords, dateFormat } from '../../../utils/HelperFuncitons';
import LimitPagination from '../../common/LimitPagination';
import CommonTable from '../../common/Table/CommonTable'
import ConfirmationModal from '../../common/ConfirmationModal';
import toast from 'react-hot-toast';

const WithdrawalRequest = () => {
  const [loader, setLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [confirmStatus, setConfirmStatus] = useState({ status: "", id: 0 })
  const { limit } = useAdminContext();

  const confirmApproveOrReject = (status, id) => {
    setConfirmStatus({ status, id })
    document.getElementById('confirmModalBtn').click()
  }

  const getAllRequest = async (selectedPage, limit) => {
    try {
      setLoader(true)
      const res = await partnerEarningWithdrawRequestApi(limit, selectedPage)
      if (res.status === 200 || res.status === 201) {
        const usersData = res?.data?.data?.withdrawList;
        const totalLength = res?.data?.data?.totalLength;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setTableData(usersData);
        setLoader(false);
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    getAllRequest(currentPage, limit)
    setSelectedPage(currentPage)
  };

  const getWalletAmount = (item) => {
    const role = item?.role?.[0]
    return role === "driver" ? (item?.driverDetails?.length ? item.driverDetails[0].totalEarnings : 0)
      : role === "hire" ? item?.rentalVendor?.totalEarnings
        : role === "restaurant" ? item?.restaurant?.totalEarnings
          : role === "salesAgent" ? item?.salesAgent?.totalEarnings
            : 0
  }

  const headerData = [
    { name: "S.NO", className: "col-1", key: "sNo" },
    { name: "Name", className: "col-1", key: "name" },
    { name: "Phone", className: "col-1", key: "phone" },
    { name: "Role", className: "col-1", key: "role" },
    { name: "Date", className: "col-2", key: "date" },
    { name: "Requested Amount", className: "col-1", key: "amount" },
    { name: "Wallet Amount", className: "col-1", key: "walletAmount" },
    { name: "Status", className: "col-1", key: "status" },
    { name: "Action", className: "col-1", key: "action" },
  ]

  const RequestTableData = tableData.map((item, index) => ({
    sNo: index + 1,
    name: item?.partnerUser?.partnerUserDetails?.[0]?.name ? capitalizeWords(item?.partnerUser?.partnerUserDetails?.[0]?.name) : "-",
    phone: item?.partnerUser?.phone ? capitalizeWords(item?.partnerUser?.phone) : "-",
    role: item.role ? capitalizeWords(item.role === "salesAgent" ? "Sales Agent" : item.role) : "-",
    date: dateFormat(item.createdAt),
    amount: item?.amount ?? "0",
    walletAmount: getWalletAmount(item?.partnerUser),
    status: item?.status === "approved" ? "Paid" : (item?.status || "-"),
    action: item?.status === "pending" ? (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li className="dropdown-item" onClick={() => confirmApproveOrReject('approved', item?.id)}>
            <div style={{ cursor: "pointer" }}
            >
              Mark as Paid
            </div>
          </li>
          <li className="dropdown-item" onClick={() => confirmApproveOrReject('rejected', item?.id)}>
            <div style={{ cursor: "pointer" }}
            >
              Reject
            </div>
          </li>
        </ul>
      </div>
    ) : null
  }))

  const handleChangeStatus = async () => {
    try {
      setLoader(true)
      await changePartnerEarningWithdrawRequestStatusApi(confirmStatus)
      getAllRequest();
      document.getElementById('confirmationModal').click()
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something Went Wrong")
      setLoader(false)
    }
  }

  useEffect(() => {
    getAllRequest(selectedPage, limit);
  }, [])

  return (

    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}
            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  <div className="card">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0 py-4">
                      <LimitPagination getData={getAllRequest} />
                      <div style={{ display: "-webkit-inline-box" }}>
                      </div>
                    </div>
                    <CommonTable headers={headerData} data={RequestTableData} loading={loader} />
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}

      <button
        className="d-none"
        id="confirmModalBtn"
        data-bs-toggle="modal"
        data-bs-target="#confirmationModal">
        openmodal
      </button>
      <ConfirmationModal
        handleConfirm={handleChangeStatus}
        confirmStatus={confirmStatus}
        message={"Are You Sure?"}
      />

    </>

  )
}




export default WithdrawalRequest