import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getAppVersionDataApi, updateAppVersionDataApi } from '../../../utils/ApiEndpointFunctions';
import Spinwheel from '../../common/Spinwheel';

const AppVersionForm = () => {
    const [appVersionData, setAppVersionData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleInputChange = (id, field, value) => {
        setAppVersionData(prevData =>
            prevData.map(app =>
                (app.id && app.id === id) ? { ...app, [field]: value } : app
            )
        );
    };

    useEffect(() => {
        const getAppVersionData = async () => {
            try {
                setIsLoading(true)
                const response = await getAppVersionDataApi()
                if (response && response.status === 200) {
                    setAppVersionData(response.data.data)
                }
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.log(error)
                toast.error(error.message)
            }
        }
        getAppVersionData()
    }, [])

    return (
        <div className="container">
            <div className="card">
                <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
                    APP VERSION
                </div>
                <div className="card-body" >
                    <div className='p-5'>
                        {isLoading ?
                            <div className='text-center'>
                                <Spinwheel />
                            </div>
                            : <>
                                <form>
                                    {/* User App Version */}
                                    <h3 className="mt-3 fw-bold">USER APP VERSION</h3>
                                    <VersionInputRow platform="Android" appType="user" versionData={appVersionData} onInputChange={handleInputChange} />
                                    <VersionInputRow platform="iOS" appType="user" versionData={appVersionData} onInputChange={handleInputChange} />

                                    {/* Partner App Version */}
                                    <h3 className="mt-4 fw-bold">PARTNER APP VERSION</h3>
                                    <VersionInputRow platform="Android" appType="partner" versionData={appVersionData} onInputChange={handleInputChange} />
                                    <VersionInputRow platform="iOS" appType="partner" versionData={appVersionData} onInputChange={handleInputChange} />
                                </form>
                            </>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppVersionForm;

const VersionInputRow = ({ platform, appType, versionData, onInputChange }) => {
    const [loader, setLoader] = useState(false);

    if (!versionData || !Array.isArray(versionData)) return null;

    const handleChange = (field, value) => {
        onInputChange(app.id, field, value);
    };

    const app = versionData.find(app => app.appName === appType && app.type === platform.toLowerCase());

    if (!app) return null;

    const handleSubmit = async () => {
        if(loader) return;
        setLoader(true)

        const promise = updateAppVersionDataApi(app);
        toast.promise(
            promise,
            {
                loading: 'Updating...',
                success: (res) => {
                    if (res.status === 200) {
                        setLoader(false)
                        return res.data.message || 'Success';
                    }
                    setLoader(false)
                },
                error: (err) => {
                    setLoader(false)
                    return err?.response?.data?.message || 'Something Went Wrong'
                }
            }
        )
    };

    return (
        <>
            <div className="form-group row">
                {/* <label htmlFor={`${appType}${platform}VersionCode`} className="col-sm-2 col-form-label">{`${platform} Version Code`}</label>
                <div className="col-sm-2">
                    <input type="text" className="form-control" id={`${appType}${platform}VersionCode`} placeholder="0" value={app.versionCode} onChange={(e) => handleChange('versionCode', e.target.value)} />
                </div> */}
                <label htmlFor={`${appType}${platform}VersionName`} className="col-sm-2 col-form-label">{`${platform} Version Name`}</label>
                <div className="col-sm-4">
                    <input type="text" className="form-control" id={`${appType}${platform}VersionName`} placeholder="0" value={app.versionName} onChange={(e) => handleChange('versionName', e.target.value)} />
                </div>
                <div className="form-check form-switch col-sm-4 d-flex align-items-center justify-content-center">
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={app.isMandatory} onChange={(e) => handleChange('isMandatory', e.target.checked)} />
                    <label htmlFor="subscription" className="form-label ms-1 mt-2">Mandatory</label>
                </div>
                <div className="col-sm-2 d-flex align-items-center" onClick={handleSubmit} >
                    <button type="button" className="btn btn-sm btn-primary" disabled={loader}>Submit</button>
                </div>
            </div>
        </>
    );
};
