import React, { useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { capitalizeWords, removeToken } from '../../../../utils/HelperFuncitons';
import LimitPagination from '../../../common/LimitPagination';
import { S3_URL } from '../../../../utils/BaseUrl';
import ImageViewModal from '../../../common/Modal/ImageViewModal';
import CommonTable from '../../../common/Table/CommonTable';
import { getAdminDetailsApi, updateDiscountOnOffApi } from '../../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { discountFormikInitialValues, discountFormikValidationSchema } from '../../../common/Validation';
import { debounce } from 'lodash';

function DriverTable(props) {
  const { setConfirmStatus, getUsers, loader, users, selectedPage, page, totalCount, handlePageClick, debouncedOnChange } = props;
  const location = useLocation();
  const imageViewModalId = "imageViewModalId"
  const [selectedImg, setSelectedImg] = useState("")
  const { limit, adminDetail, setAdminDetail } = useAdminContext();
  const [resDisLoader, setResDisLoader] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const partnersRole = searchParams.get("type");
  const activeTab = searchParams.get("tab");

  const confirmApproveOrReject = (status, userId, role) => {
    setConfirmStatus({ status, userId, role })
    document.getElementById('confirmModalBtn').click()
  }

  const headerData = [
    { name: "S.No", className: "col-1", key: "index" },
    { name: "Name", className: "col-3", key: "name" },
    { name: "Email ID", className: "col-1", key: "email" },
    { name: "Contact", className: "col-1", key: "phone" },
    { name: "Country", className: "col-1", key: "country" },
    { name: "City", className: "col-1", key: "city" },
    { name: "Role", className: "col-1", key: "role" },
    { name: "Status", className: "col-1", key: "approvalStatus" },
    { name: "Action", className: "col-1", key: "action" },
  ]

  const tableData = users?.map((user, index) => ({
    index: index + 1,
    name: (
      <>
        <div className="avatar avatar-xs align-middle me-2">
          <img
            className="avatar-img rounded-circle"
            src={!user.profileImage ? "/images/profiledp.png" : `${S3_URL}/${user?.profileImage}`}
            alt="img"
            data-bs-toggle="modal"
            data-bs-target={`#${imageViewModalId}`}
            style={{ cursor: 'pointer' }}
            onClick={() => { setSelectedImg(!user.profileImage ? "/images/profiledp.png" : `${S3_URL}/${user?.profileImage}`) }}
          />
        </div>
        <Link className="text-primary" to={
          user.role?.includes?.("driver") ? `/partners/driver/${user.id}`
            : user.role?.includes?.("hire") ? `/partners/rental/${user.id}`
              : user.role?.includes?.("restaurant") ? `/partners/restaurent/${user.id}`
                : user.role?.includes?.("salesAgent") ? `/partners/salesAgent/${user.id}`
                  : location.pathname + location.search
        } style={{ textWrap: "noWrap" }}>{capitalizeWords(user?.name)}</Link>
      </>
    ),
    email: user?.email || "-",
    phone: user?.phone || "-",
    country: user?.country || "-",
    city: user?.city || "-",
    role: user.role?.map(el => capitalizeWords(el === "salesAgent" ? "Sales Agent": el))?.join(", "),
    approvalStatus: user?.approvalStatus ? capitalizeWords(user?.approvalStatus) : "",
    action: user?.approvalStatus === "pending" ? (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul className="dropdown-menu" style={{ zIndex: "1000" }}>
          <li onClick={() => confirmApproveOrReject('approved', user?.id, user?.lastActive)}>
            <a className="dropdown-item" href="#">
              Approve
            </a>
          </li>
          <li onClick={() => confirmApproveOrReject('rejected', user?.id, user?.lastActive)}>
            <a className="dropdown-item" href="#">
              Reject
            </a>
          </li>
        </ul>
      </div>
    ) : null,
  })) 


  const discountFormik = useFormik({
    initialValues: { ...discountFormikInitialValues, restaurentDiscountOnOff: adminDetail?.adminConfigurationModel?.restaurentDiscountOnOff, restaurantDiscount: adminDetail?.adminConfigurationModel?.restaurantDiscount},
    validationSchema: discountFormikValidationSchema,
    onSubmit: async (values) => {
      setResDisLoader(true)
      try {
        const res = await updateDiscountOnOffApi(values)
        toast.success(res.data?.message)
        setAdminDetail({ ...adminDetail, adminConfigurationModel: res.data?.data })
      } catch (error) {
        toast.error(error?.response?.data?.message || "Something Went Wrong")
      }
      setResDisLoader(false)
    }
  });

  const debouncedSubmitHandler = useMemo(() => debounce(discountFormik.handleSubmit, 1000),[]);


  useEffect(() => {

    if ((partnersRole === "restaurant" && activeTab === "restaurant")) {

      debouncedSubmitHandler()
    }
  }, [discountFormik.values])

  // console.log(adminDetail)
  

  return (
    < >
      <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
        <LimitPagination getData={getUsers} />
        <div className="d-flex gap-2" style={{ display: "-webkit-inline-box" }}>

          {(partnersRole ==="restaurant" &&  activeTab === "restaurant") && <> <div className='d-flex align-items-center gap-3'>
            <label htmlFor='restaurantDiscount' className=" col-form-label">Discount(%)</label>
            <div className="">
              <input type="text" className="form-control" id='restaurantDiscount' placeholder="0" name='restaurantDiscount' onChange={discountFormik.handleChange} onBlur={discountFormik.handleBlur} value={discountFormik.values.restaurantDiscount} />
              {discountFormik.touched?.restaurantDiscount && discountFormik.errors?.restaurantDiscount ? (<div className='req-error'>{discountFormik?.errors?.restaurantDiscount}</div>) : null}
            </div>
          </div>

          <div className='d-flex align-items-center gap-3'>
            <label htmlFor='descountOnOff' className=" col-form-label">Discount(on/off)</label>
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" disabled={resDisLoader} checked={discountFormik?.values?.restaurentDiscountOnOff} id="restaurentDiscountOnOff" onChange={(e) => { discountFormik.setFieldValue("restaurentDiscountOnOff", e.target.checked) }} />              
            </div>
            </div></>}

          <div className="position-relative my-3 d-flex justify-content-between ">
            <input
              className="form-search me-3"
              type="search"
              name="searchField"
              id=""
              placeholder="Search by email"
              onChange={debouncedOnChange}
            />
          </div>
          
        </div>
      </div>
      
      <CommonTable headers={headerData} data={tableData} loading={loader} />

      <ImageViewModal imgUrl={selectedImg} modalId={imageViewModalId} />
      {totalCount > limit ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={selectedPage - 1}
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default DriverTable