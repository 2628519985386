import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ headers }) => {
    return (
        <>
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index} className={header.className}>
                            <span className="list-sort text-muted">{header.name}</span>
                        </th>
                    ))}
                </tr>
            </thead>
        </>
    );
};

TableHeader.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TableHeader;
