import React from 'react'
import { useAdminContext } from '../../context/adminContext'

function LimitPagination(props) {
  const { limit, setLimit } = useAdminContext();

  const handlePaginationLimit = (e) => {
    setLimit(e.target.value)
    if (props.getData) {
      props.getData(1, e.target.value)
    }
  }

  return (
    <div>
      <span htmlFor="limit">Show</span>
      <select className="form-select-sm ms-1 me-1" id='limit' data-choices value={limit} onChange={handlePaginationLimit}  >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
        <option value="50">50</option>
      </select>
      <span htmlFor="limit">Entries</span>
    </div>
  )
}

export default LimitPagination