import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAdminContext } from '../../../../context/adminContext';
import { getAllAdminListApi, enableOrdisableAdministratorApi } from '../../../../utils/ApiEndpointFunctions';
import AdministratorTable from './AdministratorTable';
import DeletedModal from '../../../common/Modal/DeletedModal';
import ReactPaginate from 'react-paginate';


const Administrator = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [confirmStatus, setConfirmStatus] = useState({ status: "", id: "" })
  const [deleteLoader, setDeleteLoader] = useState(false)
  

  const deleteModalId = "deleteModalId"

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("tab");

  const { limit } = useAdminContext();
  let currentPage = "";

  const debouncedOnChange = debounce((e) => getAdminList(selectedPage, limit, e.target.value), 800);

  const handlePageClick = (event) => {
    currentPage = event.selected + 1;
    getAdminList(currentPage, limit)
    setSelectedPage(currentPage)
  };

  async function getAdminList(page, limit, search="") {
    try {
      setLoader(true);
      const res = await getAllAdminListApi(page, limit, search);
      if (res.status === 200 || res.status === 201) {
        const usersData = res?.data?.data?.adminList;
        const totalLength = res?.data?.data?.totalLength;
        setTotalCount(totalLength);
        setPage(Math.ceil(totalLength / limit));
        setData(usersData);
        setLoader(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  }

  const handleChangeStatus = async () => {
    try {
      setDeleteLoader(true)
      await enableOrdisableAdministratorApi(confirmStatus)
      await getAdminList(selectedPage, limit)
      document.getElementById(deleteModalId).click()
    } catch (error) {
      console.log(error)
    }
    setDeleteLoader(false)

  }


  useEffect(() => {
    getAdminList(selectedPage, limit);
  }, [])

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* <BreadCrumb /> */}

            <div className="main-content">
              {/* HEADER */}
              <div className="row">
                <>
                  {(activeTab === 'active' || activeTab == null) && (
                    <div className="card" >
                      <AdministratorTable
                        setConfirmStatus={setConfirmStatus}
                        loader={loader}
                        data={data}
                        selectedPage={selectedPage}
                        page={page}
                        totalCount={totalCount}
                        handlePageClick={handlePageClick}
                        debouncedOnChange={debouncedOnChange}
                        getAdminList={getAdminList}
                        deleteModalId={deleteModalId}
                      />
                    </div>
                  )}
                </>
              </div>
              {totalCount > limit && (
                <div className="d-flex justify-content-center mt-3">
                  <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={Math.ceil(totalCount / limit)}
                    previousLabel="< Previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName={"active"}
                    forcePage={currentPage}
                  />
                </div>
              )}
              <DeletedModal
                modalId={deleteModalId}
                message={"This Admin"}
                deletedHandler={handleChangeStatus}
                loader={deleteLoader}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Administrator
