import React from 'react'
import Spinwheel from '../Spinwheel'

function DeletedModal(props) {
  const { modalId, message, deletedHandler,loader } = props
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0 block-user">
              <img src="/images/blockuser.png" alt="" width={202} srcSet="" />
              <h1 className="mb-0">
                Are you sure you want to delete {message ? message : ""} ?
              </h1>
            </div>
            <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 ">
              <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0">
                <button
                  type="button"
                  className="btn-custom w-auto"
                  onClick={deletedHandler}
                  disabled={loader}
                >
                  {loader ? <Spinwheel sizeClass={"spin-small"} /> : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeletedModal