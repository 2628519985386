import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { createOrUpdateSurgePeriodApi, getAdminConfigurationApi, surgePeriodListApi, updateAdminConfigurationsApi, updateSurgePeriodStatus, enableOrDisableSurgePeriodApi } from '../../../../utils/ApiEndpointFunctions';
import { capitalizeWords, convertTimeTo12HrFormat } from '../../../../utils/HelperFuncitons';
import DeletedModal from '../../../common/Modal/DeletedModal';
import RidesFormModal from '../../../common/Modal/RidesFormModal';
import SurChargePriodFormModal from '../../../common/Modal/SurChargePriodFormModal';
import { surchargePeriodInitialValue, surchargePeriodValidationSchema } from '../../../common/Validation';
import { useSearchParams } from 'react-router-dom';
import CommonTable from '../../../common/Table/CommonTable';

function SurchargePriodsTable({ categoryType, categoryId }) {
  const [loader, setLoader] = useState(true);
  const [addLoader, setAddLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const SurChargePriodFormModalId = "SurChargePriodFormModalId"
  const deleteModalId = "surChargePriodDeleteModal"
  // const [peakChargesToggle, setPeakChargesToggle] = useState(false);

  const [statusLoading, setStatusLoading] = useState(false)

  const getAllPeriods = async () => {
    try {
      setLoader(true)
      const res = await surgePeriodListApi(categoryId, categoryType)
      if (res.status === 200) {
        setTableData(res.data?.data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  }


  const deletePeriod = async () => {
    setAddLoader(true)
    try {
      const res = await updateSurgePeriodStatus({ ...selectedId, status: false })
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getAllPeriods();
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const handleSubmit = async (values) => {
    try {
      let payload = JSON.parse(JSON.stringify(values));
      payload.type = categoryType;
      payload.categoryId = categoryId;

      setAddLoader(true)
      const res = await createOrUpdateSurgePeriodApi(payload)
      if (res.status === 200) {
        toast.success(capitalizeWords(res.data?.message))
        getAllPeriods();
        document.getElementById(SurChargePriodFormModalId).click();
        formik.resetForm();
      }

    } catch (error) {
      console.log(error);
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddLoader(false)
  }

  const updatingValueHandler = (value) => {
      // value.countryId = {
      //   label: value?.countryName, value: value?.countryId
      // }
      // value.cityId = {
      //   label: value?.cityName, value: value?.cityId
      // }
   
    formik.setValues(value)
  };

  const handleEnableOrDisableSurcharge = async(value,index) => {
    try{
      setStatusLoading(true)
      const res = await enableOrDisableSurgePeriodApi({id: value.id, status: !Boolean(tableData[index].isEnabled)})
      if(res.status === 200){
        toast.success(res.data.message)
        tableData[index].isEnabled = !Boolean(tableData[index].isEnabled)
        setTableData(tableData)
      }
    }catch(err){
      console.log(err)
      toast.error("Something Went Wrong")
    }
    setStatusLoading(false)
  }

  const formik = useFormik({
    initialValues: surchargePeriodInitialValue,
    onSubmit: handleSubmit,
    validationSchema: surchargePeriodValidationSchema
  })

  const headerData = [
    { name: "S.No", className: "col-1", key: "index" },
    { name: "Name", className: "col-1", key: "name" },
    { name: "From", className: "col-1", key: "from" },
    { name: "To", className: "col-1", key: "to" },
    { name: "Multiplier", className: "col-1", key: "multiplier" },
    { name: "Enable", className: "col-1", key: "enable" },
    { name: "", className: "col-1", key: "action" },
  ];

  const surgePeriodTableData = tableData.map((ele, index) => ({
    index: index + 1,
    name: ele?.periodName || "-",
    from: ele?.from ? convertTimeTo12HrFormat(ele?.from) : "-",
    to: ele?.to ? convertTimeTo12HrFormat(ele?.to) : "-",
    multiplier: ele?.multiplier || "-",
    enable: (
      <div className="form-check form-switch ">
        <input className="form-check-input" type="checkbox" checked={ele?.isEnabled} disabled={statusLoading} role="switch" id="flexSwitchCheckDefault" onChange={() => handleEnableOrDisableSurcharge(ele, index)} />
      </div>
    ),
    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li className="dropdown-item">
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${SurChargePriodFormModalId}`}
              onClick={() => { updatingValueHandler(ele) }}
              style={{ cursor: "pointer" }}
            >
              Edit
            </div>
          </li>

          <li className="dropdown-item" >
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${deleteModalId}`}
              onClick={() => { setSelectedId({ id: ele?.id, }) }}
              style={{ cursor: "pointer" }}
            >
              Delete
            </div>
          </li>
        </ul>
      </div>
    )
  }))
  

  useEffect(() => {
    if (categoryType) { 
      if (categoryId) getAllPeriods();
      else setLoader(false)
    } else {
      getAllPeriods();
    }
  }, [categoryId])

  return (
    <div className='card'>
      <div className="card-header">
        <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
          <h1>Surge period</h1>
          <div style={{ display: "-webkit-inline-box" }}>
            <div className="position-relative my-3">
              <div className="d-flex gap-2 justify-content-end align-items-center">

                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target={`#${SurChargePriodFormModalId}`}
                  onClick={formik.resetForm}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <CommonTable headers={headerData} data={surgePeriodTableData} loading={loader} />
      </div>

      <SurChargePriodFormModal modalId={SurChargePriodFormModalId} addLoader={addLoader} formik={formik} />
      <DeletedModal
        modalId={deleteModalId}
        message={"this Period"}
        deletedHandler={deletePeriod}
        loader={addLoader}
      />
    </div>
  )

}

export default SurchargePriodsTable