import React, { useState } from 'react'
import { useEffect } from 'react'
import { defaultCategoryListOptionsApi } from '../../../utils/ApiEndpointFunctions'
import Spinwheel from '../Spinwheel'

function RidesFormModal(props) {
  const { formik, addLoader, modalId, bookingType } = props
  const [allCategory,setAllCategory] = useState([])
  const [rentalVehicleType, setRentalVehicleType] = useState("")

  const getAllDefaultCategory = async() => {
    try {
      const res = await defaultCategoryListOptionsApi(bookingType);
      if(res.status === 200){
        setAllCategory(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  } 

  useEffect(() => {
    getAllDefaultCategory()
  },[])

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>Add Rides</h2>
                <form onSubmit={formik?.handleSubmit} >

                  <div className="mb-3">
                    <label htmlFor="categoryId" className="form-label" >Class</label>
                    <select className="form-select" aria-label="Default select example" id='categoryId' name='categoryId' value={formik?.values?.categoryId} onChange={(e) => {
                         const selectedOption = e.target.options[e.target.selectedIndex];
                         const rentalVehicleType = selectedOption.getAttribute('data-rental-vehicle-type');
                        setRentalVehicleType(rentalVehicleType)
                      formik?.handleChange(e)}} onBlur={formik?.handleBlur} >
                      <option value="">Select Class</option>
                      {allCategory.map((ele) => {
                        return <option key={ele?.id} value={ele?.id} data-rental-vehicle-type={ele?.rentalVehicleType}>{`${ele?.category}`}</option>
                      })}
                      
                    </select>
                    {formik?.touched?.categoryId && formik?.errors?.categoryId ? (<div className='req-error'>{formik?.errors?.categoryId}</div>) : null}
                  </div>

                  {bookingType === "yiinoRental" && formik?.values?.categoryId && rentalVehicleType && <div className="mb-3">
                    <label htmlFor="type" className="form-label">Vehicle Type</label>
                    <input type="text" className="form-control disabled-input" id="type" name='type' value={rentalVehicleType?.toUpperCase()} disabled />
                  </div>}

                  <div className="mb-3">
                    <label htmlFor="make" className="form-label">Make</label>
                    <input type="text" className="form-control" id="make" placeholder="Enter make" name='make' value={formik?.values?.make} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.make && formik?.errors?.make ? (<div className='req-error'>{formik?.errors?.make}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="model" className="form-label">Model</label>
                    <input type="text" className="form-control" id="model" placeholder="Enter model" name='model' value={formik?.values?.model} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.model && formik?.errors?.model ? (<div className='req-error'>{formik?.errors?.model}</div>) : null}
                  </div>

                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RidesFormModal