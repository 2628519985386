import React from 'react'
import { S3_URL } from '../../../utils/BaseUrl'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function ImageViewModal(props) {
  const { imgUrl, modalId } = props
   
 
  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center " style={{maxHeight:"800px"}}>
              {
                Array.isArray(imgUrl) ? <div>
                  <Carousel
                    infiniteLoop={true}
                    showIndicators={true}
                    stopOnHover={true}
                    showArrows={true}
                    showThumbs={false}
                    swipeable={true}
                    dynamicHeight={true}
                  >
                    {
                      imgUrl.map((img, index) => (
                        <div key={index} className="post_image">
                          {/* <img src={} alt="PostImg" style={{ maxWidth: "100%", height: "auto" }} /> */}
                          <img src={S3_URL + "/" + img} alt="img" className="img-fluid" style={{ maxHeight: '500px', borderRadius: "1%" }} />
                        </div>
                      ))
                    }
                  </Carousel>
                </div> : imgUrl?.split(".").slice(-1).includes("pdf") ? <embed src={imgUrl + "#toolbar=0&navpanes=0&scrollbar=0"} style={{ width: "100%", height: "700px", borderRadius: "1%" }} type="application/pdf" /> : <img src={imgUrl} alt="img" className="img-fluid" style={{ maxHeight: '500px', borderRadius: "1%" }} />

              }
              { }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImageViewModal