import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { addOrUpdateSubscriptionDriverApi, SubscriptionDriverApi, updateSubscriptionDriverStatus } from '../../../../utils/ApiEndpointFunctions';
import { capitalizeWords } from '../../../../utils/HelperFuncitons';
import DeletedModal from '../../../common/Modal/DeletedModal';
import { subscriptionDriverInitialValue, subscriptionDriverValidationSchema } from '../../../common/Validation';
import AddSubscriptionDriverModal from '../../../common/Modal/AddSubscriptionDriverModal';
import CommonTable from '../../../common/Table/CommonTable';

const SubscriptionTable = ({ categoryType, categoryId, setSubscriptionsLength }) => {
    const [loader, setLoader] = useState(true);
    const [addLoader, setAddLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const AddSubscriptionDriverModalId = "AddSubscriptionDriverModalId"
    const deleteModalId = "subscriptionDeleteModal"

    const getSubscriptionDriverList = async () => {
        try {
            setLoader(true)
            const res = await SubscriptionDriverApi(categoryId, categoryType)
            if (res.status === 200) {
                setTableData(res.data?.BonusList)
                setSubscriptionsLength(res.data?.BonusList?.length)
            }
        } catch (error) {
            console.log(error)
        }
        setLoader(false)
    }

    const deleteBonus = async () => {
        setAddLoader(true)
        try {
            const res = await updateSubscriptionDriverStatus({ ...selectedId, status: false })
            if (res.status === 200) {
                document.getElementById(deleteModalId).click();
                toast.success(capitalizeWords(res.data?.message))
                getSubscriptionDriverList();
            }
        } catch (error) {
            console.log(error)
            error.response ?
                toast.error(error.response?.data?.message) :
                toast.error("Something went wrong");
        }
        setAddLoader(false)
    }

    const handleSubmit = async (values) => {
        try {
            let payload = JSON.parse(JSON.stringify(values));
            setAddLoader(true)
            payload['duration'] = payload.duration.value
            payload.type = categoryType
            payload.categoryId = categoryId;

            const res = await addOrUpdateSubscriptionDriverApi(payload)
            if (res.status === 200) {
                toast.success(capitalizeWords(res.data?.message))
                getSubscriptionDriverList();
                document.getElementById(AddSubscriptionDriverModalId).click();
                formik.resetForm();
            }

        } catch (error) {
            console.log(error);
            error.response ?
                toast.error(error.response?.data?.message) :
                toast.error("Something went wrong");
        }
        setAddLoader(false)
    }

    const formik = useFormik({
        initialValues: subscriptionDriverInitialValue,
        onSubmit: handleSubmit,
        validationSchema: subscriptionDriverValidationSchema
    })

    const updatingValueHandler = (value) => {
        if (value?.duration) {
            value.duration = {
                label: durationOptions[value?.duration],
                value: value?.duration
            };
        }
        if (!value?.from || !value?.to) {
            value.from = ""
            value.to = ""
        }
        formik.setValues(value);
    };


    const durationOptions = {
        'daily': 'Daily',
        'weekly': 'Weekly (7 Days)',
        'biWeekly': 'Bi-Weekly (14 Days)',
        'monthly': 'Monthly (30 Days)',
    }


    const headerData = [
        { name: "S.No", className: "col-1", key: "index" },
        { name: "Name", className: "col-1", key: "name" },
        { name: "Duration", className: "col-1", key: "duration" },
        { name: "Amount", className: "col-1", key: "amount" },
        { name: "", className: "col-1", key: "action" },

    ];

    const subscriptionTableData = tableData.map((ele, index) => ({
        index: index + 1,
        name: ele?.name || "-",
        duration: ele?.duration && typeof ele.duration === "object" ? (ele?.duration?.label) : ele?.duration ? capitalizeWords(ele.duration) : "-",
        amount: ele?.amount || "-",
        action: (
            <div className="dropdown">
                <button
                    className=" border-0 bg-white"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-three-dots-vertical"
                        viewBox="0 0 16 16"
                    >
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                </button>
                <ul
                    className="dropdown-menu"
                    style={{ zIndex: "1000" }}
                >
                    <li className="dropdown-item">
                        <div
                            data-bs-toggle="modal"
                            data-bs-target={`#${AddSubscriptionDriverModalId}`}
                            onClick={() => { updatingValueHandler(ele) }}
                            style={{ cursor: "pointer" }}
                        >
                            Edit
                        </div>
                    </li>

                    <li className="dropdown-item" >
                        <div
                            data-bs-toggle="modal"
                            data-bs-target={`#${deleteModalId}`}
                            onClick={() => { setSelectedId({ id: ele?.id, }) }}
                            style={{ cursor: "pointer" }}
                        >
                            Delete
                        </div>
                    </li>
                </ul>
            </div>
        )
    }))
   
    useEffect(() => {
        if (categoryType) {
            if (categoryId) getSubscriptionDriverList();
            else setLoader(false)
        } else {
            getSubscriptionDriverList();
        }
    }, [])

    return (
        <div className='card'>
            <div className="card-header">
                <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
                    <h1>Subscription</h1>
                    <div style={{ display: "-webkit-inline-box" }}>
                        <div className="position-relative my-3">
                            <div className="d-flex gap-2 justify-content-center align-items-center">
                                <button
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#${AddSubscriptionDriverModalId}`}
                                    onClick={formik.resetForm}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <CommonTable headers={headerData} data={subscriptionTableData} loading={loader} />
            </div>

            <AddSubscriptionDriverModal modalId={AddSubscriptionDriverModalId} addLoader={addLoader} formik={formik}  options={durationOptions}/>
            <DeletedModal
                modalId={deleteModalId}
                message={"this Subscription"}
                deletedHandler={deleteBonus}
                loader={addLoader}
            />
        </div>
    )

}

export default SubscriptionTable;