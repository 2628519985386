import React from "react";
import { createContext, useContext, useState } from "react";

export const adminContext = createContext({
    adminDetail: null

});

export function AdminContextProvider({ children }) {
    const [adminDetail, setAdminDetail] = useState(null);
    const [limit, setLimit] = useState(10)

    return (
        <adminContext.Provider value={{ adminDetail, setAdminDetail, limit, setLimit }}>
            {children}
        </adminContext.Provider>
    );
}

export function useAdminContext() {
    return  useContext(adminContext);
}