import React from 'react'

function Spinwheel({sizeClass}) {
  return (
    <div className={`spinner-border text-dark ${sizeClass}`} role="status">
          <span className="sr-only"></span>
      </div>
  )
}

export default Spinwheel