import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { rateRestaurantInitialValues, referralInitialValues, referralValidationSchema } from '../../common/Validation';
import { addOrUpdateReferralDetailsApi, getReferralDetailsApi } from '../../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';

function ReferralDetails() {
  const [submitLoader, setSubmitLoader] = useState(false);

  const getReferralDetails = async () => {
    try {
      const res = await getReferralDetailsApi()
      if(res.status === 200) {
        referralFormik.setValues(res.data.data)
      }
    } catch (error) {
      
    }
  } 


  const referralFormik = useFormik({
    initialValues: referralInitialValues,
    validationSchema: referralValidationSchema,
    onSubmit: async (values) => {
      try {
        setSubmitLoader(true)
        const res = await addOrUpdateReferralDetailsApi(values)
        if(res.status === 200) {

          toast.success(res.data.message);
        }

      } catch (error) {
        console.log(error)
      }
      setSubmitLoader(false)
    }
  })

  useEffect(() => {
    getReferralDetails();
  }, [])

  return (
    <div className="card">
      <div className="card-header" style={{ backgroundColor: "var(--color-card-bg)" }}>
        Referral
      </div>
      <div className="card-body" >
        <div className='p-5'>
          <form onSubmit={referralFormik.handleSubmit}>
            <div className="form-group row row-cols-4">

              <div className='d-flex gap-3'>
                <label htmlFor='minTrips' className=" col-form-label">Min trip</label>
                <div className="">
                  <input type="text" className="form-control" id='minTrips' name='minTrips' placeholder="0" onChange={referralFormik.handleChange} onBlur={referralFormik.handleBlur} value={referralFormik.values.minTrips} />
                  {referralFormik.touched?.minTrips && referralFormik.errors?.minTrips ? (<div className='req-error'>{referralFormik?.errors?.minTrips}</div>) : null}
                </div>
              </div>

              <div className='d-flex gap-3'>
                <label htmlFor='minReferral' className=" col-form-label">Min referral</label>
                <div className="">
                  <input type="text" className="form-control" id='minReferral' placeholder="0" name='minReferral' onChange={referralFormik.handleChange} onBlur={referralFormik.handleBlur} value={referralFormik.values.minReferral} />
                  {referralFormik.touched?.minReferral && referralFormik.errors?.minReferral ? (<div className='req-error'>{referralFormik?.errors?.minReferral}</div>) : null}
                </div>
              </div>

              <div className='d-flex gap-3'>
                <label htmlFor='minTripCharges' className=" col-form-label">Min trip charges</label>
                <div className="">
                  <input type="text" className="form-control" id='minTripCharges' placeholder="0" name='minTripCharges' onChange={referralFormik.handleChange} onBlur={referralFormik.handleBlur} value={referralFormik.values.minTripCharges} />
                  {referralFormik.touched?.minTripCharges && referralFormik.errors?.minTripCharges ? (<div className='req-error'>{referralFormik?.errors?.minTripCharges}</div>) : null}
                </div>
              </div>

              <div className='d-flex gap-3'>
                <label htmlFor='referralPrice' className=" col-form-label">Referral price</label>
                <div className="">
                  <input type="text" className="form-control" id='referralPrice' placeholder="0" name='referralPrice' onChange={referralFormik.handleChange} onBlur={referralFormik.handleBlur} value={referralFormik.values.referralPrice} />
                  {referralFormik.touched?.referralPrice && referralFormik.errors?.referralPrice ? (<div className='req-error'>{referralFormik?.errors?.referralPrice}</div>) : null}
                </div>
              </div>

              <div className='d-flex align-items-center gap-3'>
                <label htmlFor='referralOnOff' className=" col-form-label">Referral (on/off)</label>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" role="switch" checked={referralFormik?.values?.referralOnOff} id="referralOnOff" onChange={() => referralFormik?.setFieldValue("referralOnOff", !Boolean(referralFormik?.values?.referralOnOff))} />
                  {referralFormik.touched?.referralOnOff && referralFormik.errors?.referralOnOff ? (<div className='req-error'>{referralFormik?.errors?.referralOnOff}</div>) : null}
                </div>
              </div>
              <div className="d-flex align-items-end"  >
                <button type="submit" className="btn btn-sm btn-primary" disabled={submitLoader}>Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReferralDetails