import React, { useEffect, useMemo, useState } from 'react'
import Spinwheel from '../../common/Spinwheel'
import TableHeader from '../../common/TableHeader'
import toast from 'react-hot-toast';
import { addOrUpdateServieAreaCitiesApi, deleteServiceAreaCityApi, enableOrDisableServiceAreaCitiesApi, enableOrDisableServiceAreaCountryApi, serviceAreaCitiesApi, serviceAreaCountriesApi } from '../../../utils/ApiEndpointFunctions';
import CountryAndCityModal from '../../common/Modal/CountryAndCityModal';
import DeletedModal from '../../common/Modal/DeletedModal';
import { capitalizeWords } from '../../../utils/HelperFuncitons';
import { useFormik, validateYupSchema } from 'formik';
import { addCountryAndCityInitailValue, addCountryAndCityValidationSchema } from '../../common/Validation';
import { debounce } from 'lodash';
import CommonTable from '../../common/Table/CommonTable'

function CountryAndCites() {
  const [loader, setLoader] = useState(true)
  const [statusLoading, setStatusLoading] = useState(false);
  const [countries, setCountries] = useState([])
  const [cites, setCites] = useState([])
  const countryOrCiteModal = "cityandCountryAddOrUpdateModalID"
  const offcanvasCity = "offcanvasRightcoutry"
  const [selectCountry, setSelectCountry] = useState();
  const [search, setSearch] = useState('')
  const [cityLoader,setCityLoader] = useState(true)
  const [addCityModal,setAddCityModal] = useState(false)
  const [selectCity, setSelectCity] = useState()
  const deleteModalId = "deleteModalIds"


 

  const handleSubmit = async (values) => {
    try {
      setAddCityModal(true)
      const payload = {
        cityName: values.name?.name,
        place_id: values.name?.place_id,
        countryId: selectCountry?.id,
      }
      const res = await addOrUpdateServieAreaCitiesApi(payload)
      if(res.status === 200) {
        // console.log(res.data)
        toast.success(res.data?.message);
        getCityList();
        document.getElementById(countryOrCiteModal).click();
        formik.resetForm();
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.message ? error.response.data.message: "Something went wrong");
    }
    setAddCityModal(false)
  }

  const formik = useFormik({
    initialValues: addCountryAndCityInitailValue,
    onSubmit: handleSubmit,
    validationSchema: addCountryAndCityValidationSchema
  })


  const getCountryList = async () => {
    try {
      setLoader(true)
      const res = await serviceAreaCountriesApi()
      if (res.status === 200) {
        setCountries(res.data?.data)
        
      }
    } catch (error) {
      console.log(error)
    }
    setLoader(false)
  };

  const getCityList = async (countryId, search) => {
    try {
      if (!(countryId ? countryId : selectCountry?.id)) return
      setCityLoader(true)
      const res = await serviceAreaCitiesApi(countryId ? countryId : selectCountry?.id, search)
      if (res.status === 200) {
        setCites(res.data?.data)
        
      }
    } catch (error) {
      console.log(error)
    }
    setCityLoader(false)
  };

  const debouncedOnChange = useMemo(() => {
    return debounce(getCityList, 500);
  }, [selectCountry])

  useEffect(() => {
    debouncedOnChange(selectCountry?.id, search)
  }, [search])

  const selectCountryHandler = (ele) => {
    setSelectCountry(ele)
    getCityList(ele?.id)
  }

  const enableOrDisableCity = async (ele) => {
    try {
      setStatusLoading(true);
      const res = await enableOrDisableServiceAreaCitiesApi({cityId: ele.id, status: !ele.isActive})
      if (res.status === 200) {
        toast.success(res.data.message)
        getCityList()
      }
    } catch (error) {
      console.log(error)
    }
    setStatusLoading(false);
  }

  const removeCityHandler = async () => {
    setAddCityModal(true)
    try {
      const res = await deleteServiceAreaCityApi({cityId:selectCity.id})
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getCityList();
      }
    } catch (error) {
      console.log(error)
      error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("Something went wrong");
    }
    setAddCityModal(false)
  }

  const handleEnableOrDisableCountry = async(id, status) => {
    try {
      setStatusLoading(true)
      const res = await enableOrDisableServiceAreaCountryApi({countryId: id, status})
      if (res.status === 200) {
        document.getElementById(deleteModalId).click();
        toast.success(capitalizeWords(res.data?.message))
        getCountryList();
      }
    } catch (error) {
      console.log(error)
        toast.error(error?.response?.data?.message || "Something Went Wrong")
    }
    setStatusLoading(false)
  }

  const countryheaderData = [
    { name: "S.No", className: "col", key:"index" },
    { name: "country", className: "col", key:"name" },
    { name: "city Count", className: "col", key:"citiesCount" },
    { name: "Active", className: "col", key:"isActive" },
  ]

  const countryTableData = countries.map((item, index) => ({
    index: index + 1,
    name: (
      <div style={{ cursor: "pointer" }} className='text-primary' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCites" onClick={() => { selectCountryHandler(item) }}>{item?.name}</div>
    ),
    citiesCount: item.citiesCount,
    isActive: (
      <div className="form-check form-switch ">
        <input className="form-check-input" type="checkbox" checked={item?.isActive} role="switch" id="flexSwitchCheckDefault" onChange={() => handleEnableOrDisableCountry(item?.id, !item?.isActive)} />
      </div>
    )
  }))

  const cityheaderData = [
    { name: "S.No", className: "col", key: "index" },
    { name: "city", className: "col", key: "name" },
    { name: "Active", className: "col", key: "isActive" },
    { name: "Action", className: "col text-center", key: "Action" },
  ]

  const cityTableData = cites.map((item, index) => ({
    index: index + 1,
    name: item.name,
    isActive: (
      <div className="form-check form-switch ">
        <input className="form-check-input" type="checkbox" checked={item?.isActive} disabled={statusLoading} role="switch" id="flexSwitchCheckDefault" onChange={() => { enableOrDisableCity(item) }} />
      </div>
    ),
    Action: (
      <div className="dropdown text-center">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          <li
            onClick={() => { setSelectCity(item); }}
            data-bs-toggle="modal"
            data-bs-target={`#${deleteModalId}`}
            style={{ cursor: "pointer" }}
          >
            <span className="dropdown-item" >
              Delete
            </span>
          </li>
        </ul>
      </div>
    )
  }))


  useEffect(() => {
    getCountryList();
  },[])

  return (
    <>

      <div className='card'>
        <div className="card-header">
          <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
            <h1>Countries</h1>
            <div style={{ display: "-webkit-inline-box" }}>
              <div className="position-relative my-3">
                <div className="d-flex gap-2 justify-content-end align-items-center">

                  {/* <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                  >
                    Add
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
        <CommonTable headers={countryheaderData} data={countryTableData} loading={loader}/>

        </div>
      </div>



      <div className="offcanvas offcanvas-end" style={{ width: "45%" }} tabIndex="-1" id="offcanvasRightCites" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <h1 id="offcanvasRightLabel">{selectCountry?.name || "Cities"}</h1>
          <button type="button" className="btn-close text-reset" id={offcanvasCity} data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="card">
            <div className="card-header p-0">
              <div className="d-flex gap-2 justify-content-end align-items-center">
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target={`#${countryOrCiteModal}`}

                >
                  Add
                </button>

                <input
                  className="form-search me-3"
                  type="search"
                  name="searchField"
                  id=""
                  placeholder="Search city"
                  onChange={(e) => setSearch(e.target.value)}
                />

              </div>
            </div>

            <div className="card-body">
            <CommonTable headers={cityheaderData} data={cityTableData} loading={cityLoader}/>

            </div>
          </div>
        </div>
      </div>

      <CountryAndCityModal type={"City"} modalId={countryOrCiteModal} formik={formik} loader={addCityModal} selectedCountry={selectCountry} />
      <DeletedModal
        modalId={deleteModalId}
        message={"This city"}
        deletedHandler={removeCityHandler}
        loader={addCityModal}
      />
    </>
  )
}

export default CountryAndCites