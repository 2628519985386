import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import DeletedModal from '../../../common/Modal/DeletedModal';
import { addCategoryMakeAndModelApi, getCategoryMakeAndModelApi, updateMakeAndModelStatusApi } from './../../../../utils/ApiEndpointFunctions';
import RidesFormModal from '../../../common/Modal/RidesFormModal';
import { ridesInitialValue, ridesValidationSchema } from '../../../common/Validation';
import { capitalizeWords } from '../../../../utils/HelperFuncitons';
import CommonTable from '../../../common/Table/CommonTable';

function RidesTable() {

    const [loader, setLoader] = useState(true);
    const [addLoader, setAddLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const RidesFormModalId = "categoryFormModal"
    const deleteModalId = "RideDeleteModal"

    const getAllRides = async () => {
      try {
        setLoader(true)
        const res = await getCategoryMakeAndModelApi("YiinoRide")
        if (res.status === 200) {
          setTableData(res.data?.data?.categoryList)
        }
      } catch (error) {
        console.log(error)
      }
      setLoader(false)
    }

    const deleteRides = async () => {
      setAddLoader(true)
      try {
        const res = await updateMakeAndModelStatusApi({ ...selectedId, status: false })
        if (res.status === 200) {
          document.getElementById(deleteModalId).click();
          toast.success(capitalizeWords(res.data?.message))
          getAllRides();
        }
      } catch (error) {
        console.log(error)
        error.response ?
          toast.error(error.response?.data?.message) :
          toast.error("Something went wrong");
      }
      setAddLoader(false)
    }

    const handleSubmit = async (values) => {
      try {
        setAddLoader(true)
        const res = await addCategoryMakeAndModelApi(values)
        if (res.status === 200) {
          toast.success(capitalizeWords(res.data?.message))
          getAllRides();
          document.getElementById(RidesFormModalId).click();
          formik.resetForm();
        }
      } catch (error) {
        toast.error(error.response?.data?.message || "Something went wrong");
        console.log(error)
      }
      setAddLoader(false)
    }

    const formik = useFormik({
      initialValues: ridesInitialValue,
      onSubmit: handleSubmit,
      validationSchema: ridesValidationSchema
    })

    useEffect(() => {
      getAllRides();
    }, [])

  const headerData = [
    { name: "S.No.", className: "col-2", key: "index" },
    { name: "Make", className: "col-2", key: "make" },
    { name: "Model", className: "col-2", key: "model" },
    { name: "Class", className: "col-2", key: "category" },
    { name: "Action", className: "col-1", key: "action" },
  ]

  const ridesTableData = tableData?.map((ele, index) => ({
    index: index + 1,
    make: ele?.make || "-",
    model: ele?.model || "-",
    category: ele?.category || "-",
    action: (
      <div className="dropdown">
        <button
          className=" border-0 bg-white"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </button>
        <ul
          className="dropdown-menu"
          style={{ zIndex: "1000" }}
        >
          {/* <li className="dropdown-item">
          <div
            data-bs-toggle="modal"
            data-bs-target={`#${RidesFormModalId}`}
            onClick={() => { formik.setValues(ele) }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </div>
        </li> */}

          <li className="dropdown-item" >
            <div
              data-bs-toggle="modal"
              data-bs-target={`#${deleteModalId}`}
              onClick={() => { setSelectedId({ makeId: ele?.makeid, modelId: ele?.modelId }) }}
              style={{ cursor: "pointer" }}
            >
              Delete
            </div>
          </li>
        </ul>
      </div>
    ),
  })) 

    return (
      <><div className="d-flex justify-content-end flex-wrap align-items-center mt-3 mt-lg-0">
        <div style={{ display: "-webkit-inline-box" }}>
          <div className="position-relative my-3">
            {/* <input
            className="form-search me-3"
            type="search"
            name="searchField"
            id=""
            placeholder="Search by email"
            onChange={debouncedOnChange}
          // value = {search}
          /> */}
            <button
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target={`#${RidesFormModalId}`}
              onClick={formik.resetForm}
            >
              Add
            </button>
          </div>
        </div>
      </div>

        <CommonTable headers={headerData} data={ridesTableData} loading={loader} />
        
        <RidesFormModal modalId={RidesFormModalId} addLoader={addLoader} formik={formik} bookingType={"yiinoRide"}/>
        <DeletedModal
          modalId={deleteModalId}
          message={"this Ride"}
          deletedHandler={deleteRides}
          loader={addLoader}
        />
      </>
    )
  
}

export default RidesTable